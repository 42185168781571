<template>
  <div class="ratio-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    ratio: {
      type: Number,
      default: 16 / 9,
    },
    uid: {
      type: Number,
      default: utilities.getUniqueNumber(),
    },
  },
  computed: {
    percentPadding() {
      return +100 / this.ratio + "%";
    },
  },
};
</script>

<style lang="scss" scoped>
.ratio-container {
  width: 100%;
  position: relative;
  padding-bottom: v-bind(
    percentPadding
  ); /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */

  > * {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss">
.ratio-container {
  > * {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
